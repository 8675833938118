$body-bg: #282c34;
$body-color: #111;
body {
  padding: 0.5rem;
  height: 100%;
}

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/grid";
.main-clock {
  text-align: center;
  color: white;
  font-size: $h2-font-size;
  padding: 1rem;
  padding-top: 0;
  display: inline-flexbox;
}
.secondary {
  color: $secondary;
  text-align: center;
  padding-bottom: 1.5rem;
}
.labelswitch {
  font-size: $lead-font-size;
  color: $secondary;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  margin-top: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.root canvas {
  width: auto;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

@media (max-width: 768px) {
  .main-clock {
    text-align: center;
    color: white;
    font-size: $h2-font-size;
    padding: 1rem;
    padding-top: 0;
    display: inline;
  }
  .labelswitch {
    display: inline-flexbox;
    font-size: $lead-font-size;
    color: $secondary;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
